.wrapper{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction:column;
    background-color: rgba(0, 0, 0, 0.778);
    /* height:100%;
    width:100%; */
    position: fixed;
    top: 0;
    left: 0;
right: 0;
bottom: 0;
    z-index: 10;

}

.Error{
    height: auto;
    width: 600px;
    background-color: white;
    padding: 2rem;
    display: flex;
   flex-direction: column;
 margin: auto;
position: relative;
}

.icon{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.iconBTN{
    font-size: 2rem;
    position: absolute;
    top: 0.5rem;
    right: 1rem;
}

.contentWrapper{
    display: flex;
    align-items: center;
    justify-content: center;
}

.content{
    display: flex;
    flex-direction:column;
    margin-left: 3rem;
  
}
.contentError{
    display: flex;
    flex-direction:column;
    margin-left: 3rem; 
}
.contentError button{
        height: 48px;
    width: 168px;
    border-radius: 8px;
    background-color: #FCB300;
    outline: none;
    border: none;
    color: #523B00;
  font-family: Lora;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
}
.content button{
    height: 48px;
    width: 168px;
    border-radius: 8px;
    background-color: #FCB300;
    outline: none;
    border: none;
    color: #523B00;
  font-family: Lora;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
  
}

.content h1{
    color: #6CB24C;
    font-family: Lora;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 31px;
}
.contentError h1{
  color: #D26B60;
    font-family: Lora;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 31px;  
}
.content p{
    color: #171717;
    font-family: Lora;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
}
.contentError p{
     color: #171717;
    font-family: Lora;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
}


@media screen  and (max-width:540px) {
    .Error{
        height: auto;
        width: 95%;
        background-color: white;
        padding: 3rem 2rem;
        display: flex;
       flex-direction: column;
        margin-top: 12rem;
    }

    .img img{
height: 100px;
    }
.content h1{
    font-size: 1rem;
}
.content p{
    font-size: .8rem;
}


.content button{
    height: 40px;
    width: 128px;
    border-radius: 8px;
    background-color: #FCB300;
    outline: none;
    border: none;
    color: #523B00;
  font-family: Lora;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
}

.contentError button{
        height: 40px;
    width: 128px;
    border-radius: 8px;
    background-color: #FCB300;
    outline: none;
    border: none;
    color: #523B00;
  font-family: Lora;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
}
    
}