

.loginWrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction:column;
    width:100%;
    padding-bottom: 6rem;
    background-color: #FFFEFD;
}

.login{

    width: 617px;
    border-radius: 10px;
    background-color: #FFFFFF;
    box-shadow: 0 8px 64px 0 rgba(6,7,20,0.13);
    display: flex;
    flex-direction: column;
    align-items: center;
padding-top: 2rem;
margin-top: 2rem;
padding-bottom: 2rem;
}




.head{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction:column;
    margin: 2rem 0;

}
.head h1{
    font-family: 'Lora';
}


.pWrapper {
    display: flex;
    position: relative;

}



.head p{
    margin: 0 10px ;
    color: #171717;
    font-family: "Lora";
    font-size: 14px;
    letter-spacing: 0;
    line-height: 24px;
    display: flex;
    align-items: center;
}

.head .tamilP{
        margin: 10px 8px ;
        color: #171717;
        font-family: "Lora";
        font-size: 12px;
        letter-spacing: 0;
        line-height: 18px;
        display: flex;
        /* text-align: center; */
        align-items: center;
    
}

.head button{
   padding: .3rem;
    background-color: #C45D4E;
    border-radius: 100%;
    outline: none;
    border: none;
    margin-right: .5rem;
    /* background-color: transparent; */
}

.form input{
    outline: none;
    border: none;
    background-color: transparent;
    border: 1.3px solid #E0E0E0;
    height: 50px;
    border-radius: 4px;
    width: 400px;
    margin-bottom: 1rem;
    padding-left: .2rem;
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.input p{
   margin-bottom: 0rem;
   font-family: 'Lora';
}

.input {
    display: flex;
    flex-direction: column;
}



.input span{
    color: #C45C4E;
    margin-top: -.5rem;
    margin-bottom: 1rem;
}


.btn{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
}
.btn button{
    outline: none;
    border: none;
    background-color: transparent;
    background-color: #FCB300;
    width: 400px;
    height: 40px;
    color: rgb(70, 4, 4);
    font-weight: bold;
    font-family: 'Lora';
    margin-bottom: 1rem;
    border-radius: 4px;
}

.btn p span{
    color: #FCB300;
    text-decoration: underline;
    cursor: pointer;
    margin-left: 5px;
}

.signup{
    position: relative;
}


.signup::before{
    position: absolute;
    content: '';
    height: 2px;
width: 140px;
left: -11rem;
margin-top: .7rem;
background-color: rgba(0, 0, 0, 0.179);
}
.signup::after{
    position: absolute;
    content: '';
    height: 2px;
    width: 140px;
    left: 9rem;
    margin-top: .7rem;
    background-color: rgba(0, 0, 0, 0.179);
}

.signupTamil{
    position: relative;
}

.signupTamil::before{
    position: absolute;
    content: '';
    height: 2px;
width: 140px;
left: -11rem;
margin-top: .7rem;
background-color: rgba(0, 0, 0, 0.179);
}

.signupTamil::after{
    position: absolute;
    content: '';
    height: 2px;
    width: 140px;
    left: 22rem;
    margin-top: .7rem;
    background-color: rgba(0, 0, 0, 0.179);
}
.socialMedia{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 400px;
    margin-top: 2rem;
    cursor: pointer;
}
.socialMedia img{
    height: 30px;
    cursor: pointer;
}
.socialMedia img:nth-child(2){
    /* height: 30px; */
    margin: 0 2rem;
    cursor: pointer;

}

.terms{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction:column;
    margin-top: 2rem;
}

.terms p{
    font-size: 12px;
    /* color: #C45C4E; */
}

.terms p span{
    color: #FCB300;
    text-decoration: underline;
    cursor: pointer;
    margin-left: .6rem;
}

.forgot{
    display: flex;
    align-items: center;
    justify-content: space-around;
    align-items: center;
    margin: 2rem 0;

}

.forgot p{
    font-size: 14px;
    margin: 0 25px;
    font-family: 'Lora';
    
}

.forgot .Tamilp{
    font-size: 12px;
    margin: 0 25px;
    font-family: 'Lora';
    
}

.Reset{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 5rem 0 10rem 0;
    background-color: #FFFEFD;
}
.ChnagePassword{
     display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 5rem 0 10rem 0;
    background-color: #FFFEFD;
}


.Resetwrapper{
    height: 407px;
  width: 617px;
  border-radius: 10px;
  background-color: #FFFFFF;
  box-shadow: 0 8px 64px 0 rgba(6,7,20,0.13);
}
.changeWrapper{
     height: 507px;
  width: 617px;
  border-radius: 10px;
  background-color: #FFFFFF;
  box-shadow: 0 8px 64px 0 rgba(6,7,20,0.13);  
}

.showHidePassword{
    position: absolute;
    right: 1rem;
    top: 3rem;
    font-size: 1.3rem;
    color: black !important;
}


.emailWrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction:column;
    width: 100%;
    padding: 6rem 0 15rem 0;
    background-color: #FFFEFD;
}

.Resetwrapper .input{
    position: relative;

}

.inputPassword{
    position: relative;

}
.showHidBtn{
    position: absolute;
    top: 3.3rem;
    right: .6rem;
    outline: none;
    border: none;
    background-color: transparent;
    font-size: 1.2rem;
    cursor: pointer;
    z-index: 2;
}

.signupPassword{
    position: relative;
}

.signupPassword .showHidBtn{
    position: absolute;
    top: 2.6rem;
    right: .6rem;
    outline: none;
    border: none;
    background-color: transparent;
    font-size: 1.2rem;
    cursor: pointer;
    z-index: 2;
}
.signupPassword2{
    position: relative;
}

.signupPassword2 .showHidBtn{
    position: absolute;
    top: 2.6rem;
    right: .6rem;
    outline: none;
    border: none;
    background-color: transparent;
    font-size: 1.2rem;
    cursor: pointer;
    z-index: 2;
}





.Resetwrapper .input button{
    position: absolute;
    top: 2.4rem;
    outline: none;
    border: none;
    background-color: transparent;
    right: 0;
    font-size: 1.5rem;
    color: rgba(0, 0, 0, 0.279);
    
}


.changeWrapper .input{
    position: relative;
}

.changeWrapper .input button{
    position: absolute;
    top: 2.4rem;
    outline: none;
    border: none;
    background-color: transparent;
    right: 0;
    font-size: 1.5rem;
    color: rgba(0, 0, 0, 0.279);
    
}


.email{
    height: 159px;
    width: 717px;
    border-radius: 10px;
    background-color: #FFFFFF;
    box-shadow: 0 8px 64px 0 rgba(6,7,20,0.13);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.email h1 {
    font-family: 'Lora';
}


.fb{
    font-size: 2.3rem;
    margin: 0 3rem;
    color: #1877F2;
}

.passWordWrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction:column;
    width: 100%;
    padding: 6rem 0 12rem 0;
}




.password{
    height: 424px;
    width: 717px;
    border-radius: 10px;
    background-color: #FFFFFF;
    box-shadow: 0 8px 64px 0 rgba(6,7,20,0.13);
}



.passwordForm{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 2rem;
}

.passwordForm .passwordInput input{
    outline: none;
    border: none;
    background-color: transparent;
    border: 1.3px solid rgba(0, 0, 0, 0.318);
    height: 40px;
    border-radius: 4px;
    width: 400px;
    margin-bottom: 1rem;
    padding-left: .2rem;
}

.passwordInput p{
    margin-bottom: 0;
    font-family: 'Lora';
}

/* ---------------------------------------responsiveness started from here------------------------------------- */

@media screen and (max-width: 540px){
    

.loginWrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction:column;
    width:100%;
    padding-bottom: 6rem;
    background-color: #FFFEFD;
    padding: 0 0.2rem;
    box-sizing: border-box;
}

.login{
    height: auto;
    width: 100%;
    border-radius: 10px;
    background-color: #FFFFFF;
    box-shadow: 0 8px 64px 0 rgba(6,7,20,0.13);
    display: flex;
    flex-direction: column;
    align-items: center;
padding-top: 2rem;
margin-top: 2rem;
padding-bottom: 2rem;
}


.head{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction:column;
    margin: 2rem 0;
}
.head h1{
    font-family: 'Lora';
    font-size: 1.2rem;
}

.head .tamilP{
    margin: 8px 8px ;
    color: #171717;
    font-family: "Lora";
    font-size: 12px;
    white-space: pre-wrap;
    display: flex;  

}
.login{
    width: 100%;
}
.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.input{
    width: 90%;
}

.form input{
    outline: none;
    border: none;
    background-color: transparent;
    border: 1.3px solid rgba(0, 0, 0, 0.318);
    height: 40px;
    border-radius: 4px;
    width: 100% !important;
    margin-bottom: 1rem;
    padding-left: .2rem;
}

.showHidBtn{
    position: absolute;
    top: 2.8rem;
    right: .6rem;
    outline: none;
    border: none;
    background-color: transparent;
    font-size: 1.2rem;
    cursor: pointer;
    z-index: 2;
}

.input p{
   margin-bottom: 0rem;
}

.input {
    display: flex;
    flex-direction: column;
}

.input span{
    color: #C45C4E;
    margin-top: -.5rem;
    margin-bottom: 1rem;
}


.btn{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    width: 100%;
}
.btn button{
    outline: none;
    border: none;
    background-color: transparent;
    background-color: #FCB300;
    width: 90% !important;
    height: 40px;
    color: rgb(70, 4, 4);
    font-weight: bold;
    font-family: 'Lora';
    margin-bottom: 1rem;
    border-radius: 4px;
}

.btn p span{
    color: #FCB300;
    text-decoration: underline;
    cursor: pointer;
}

.signup{
    position: relative;
}

.signup::before{
position: absolute;
content: '';
height: 2px;
width: 100px;
left: -8rem;
margin-top: .7rem;
background-color: rgba(0, 0, 0, 0.179);
}
.signup::after{
position: absolute;
content: '';
height: 2px;
width: 100px;
left: 9rem;
margin-top: .7rem;
background-color: rgba(0, 0, 0, 0.179);
}

.socialMedia{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 400px;
    margin-top: 2rem;
}
.socialMedia img{
    height: 30px;
}
.socialMedia img:nth-child(2){
    /* height: 30px; */
    margin: 0 2rem;
}

.terms{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction:column;
    margin-top: 2rem;
}

.terms p{
    font-size: 10px;
    white-space: nowrap;
}

.terms p span{
    color: #FCB300;
    text-decoration: underline;
    cursor: pointer;
    white-space: nowrap;

    /* margin-left: 10px; */
}

.forgot{
    display: flex;
    align-items: center;
    justify-content: space-around;
    align-items: center;
    margin: 2rem 0;

}

.forgot p{
    font-size: 10px;
    margin: 0 25px;
}

.forgot .Tamilp{
    font-size: 10px;
    margin: 0 25px;
    white-space: pre-line;
    font-family: 'Lora';
    
}

.Reset{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 5rem 0 10rem 0;
    background-color: #FFFEFD;
}



.Resetwrapper{
    height: 407px;
  width: 617px;
  border-radius: 10px;
  background-color: #FFFFFF;
  box-shadow: 0 8px 64px 0 rgba(6,7,20,0.13);
}

.emailWrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction:column;
    width: 100%;
    padding: 6rem 0 15rem 0;
    background-color: #FFFEFD;
}

.email{
    height: 139px;
    width: 377px;
    border-radius: 10px;
    background-color: #FFFFFF;
    box-shadow: 0 8px 64px 0 rgba(6,7,20,0.13);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.email h1 {
    font-family: 'Lora';
    font-size: 1.4rem;
}


.email p{
    font-size: 10px;
}

.passWordWrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction:column;
    width: 100%;
    padding: 6rem 0 12rem 0;
}




.password{
    height: auto;
    width: 360px;
    border-radius: 10px;
    background-color: #FFFFFF;
    box-shadow: 0 8px 64px 0 rgba(6,7,20,0.13);
    padding-bottom: 3rem;
}



.passwordForm{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 2rem;
}

.passwordForm .passwordInput input{
    outline: none;
    border: none;
    background-color: transparent;
    border: 1.3px solid rgba(0, 0, 0, 0.318);
    height: 40px;
    border-radius: 4px;
    width: 330px;
    margin-bottom: 1rem;
    padding-left: .2rem;
}


.btn{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
}
.btn button{
    outline: none;
    border: none;
    background-color: transparent;
    background-color: #FCB300;
    width: 330px;
    height: 40px;
    color: rgb(70, 4, 4);
    font-weight: bold;
    font-family: 'Lora';
    margin-bottom: 1rem;
    border-radius: 4px;
}

.Resetwrapper{
    height: auto;
  width: 360px;
  border-radius: 10px;
  background-color: #FFFFFF;
  box-shadow: 0 8px 64px 0 rgba(6,7,20,0.13);
  padding-bottom: 3rem;
}

.Reset input{
    width: 330px;
}

.Resetwrapper .input button{
    position: absolute;
    top: 2.2rem;
    outline: none;
    border: none;
    background-color: transparent;
    right: 0;
    font-size: 1.5rem;
    color: rgba(0, 0, 0, 0.279);
    
}

.pWrapper {
    display: flex;
    position: relative;
    /* flex-direction: column; */
    /* left: 2.3rem; */

}

.pWrapper p{
    white-space: nowrap;
    font-size: 9.5px;
}

.forgot p{
    white-space: nowrap;
    
}

}